import React from "react";
import { isEmpty } from "lodash";
import { Link } from "@reach/router";
import styled from "styled-components";
import { globalHistory } from "@reach/router";

import Image from "@components/Image";
import Tooltip from "@components/Tooltip";

import JOBS from "@constants/jobs";
import FOOTLINKS from "@constants/footlinks";

import useSiteData from "@hooks/useSiteData";

export default () => {
  const {
    company: { name, number, address },
  } = useSiteData();

  return (
    <StyledContainer className="mt-8 mb-12">
      <StyledCopy className="pb-4 text-left">
        <StyledLogoSplit>
          <StyledLogoLink to="/">
            <StyledMarkWrapper className="mr-4 mt-2">
              <Image filename="logo-mark.png" alt={name} />
            </StyledMarkWrapper>
          </StyledLogoLink>

          <div>
            <p className="pb-1">
              © {new Date().getFullYear()} {name} Ltd
            </p>
            <p>Registered in England and Wales No. {number}</p>
          </div>
        </StyledLogoSplit>
      </StyledCopy>

      <StyledCopy className="pb-4 text-right">
        <StyledLinks className="pb-1">
          {FOOTLINKS.map(({ to, title }, key: number) => (
            <div key={key} className="pl-3">
              <StyledLink to={to}>
                {title}
                {
                  /**
                   * The remote tooltip should only be
                   * displayed on the homepage
                   */
                  !isEmpty(JOBS) &&
                  to === "careers" &&
                  globalHistory.location.pathname === "/" ? (
                    <Tooltip title="We're Hiring" />
                  ) : (
                    <></>
                  )
                }
              </StyledLink>
            </div>
          ))}
        </StyledLinks>

        {typeof window !== "undefined" &&
          window.innerWidth > 900 &&
          `${address}.`}
      </StyledCopy>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }
`;

const StyledCopy = styled.div`
  color: var(--text-color-2);

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    &&& {
      text-align: center;
    }

    &&& {
      &.text-right {
        text-align: center;
      }
    }
  }
`;

const StyledLogoSplit = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: block;
    text-align: center;
  }
`;

const StyledLogoLink = styled(Link)`
  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: none;
  }
`;

const StyledLinks = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: block;
  }
`;

const StyledLink = styled(Link)`
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    &&& {
      display: block;
      padding-right: 0.75rem;
      padding-bottom: 0.5rem;
    }
  }
`;

const StyledMarkWrapper = styled.div`
  width: 42px;
`;
