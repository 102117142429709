import { useStaticQuery, graphql } from "gatsby";

const useSiteData = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            company {
              name
              owner
              number
              address
              phone {
                link
                display
              }
              emails {
                contact
              }
              social {
                linkedIn
              }
            }
          }
        }
      }
    `
  );

  return site.siteMetadata;
};

export default useSiteData;
