import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
}

export default ({ title }: Props) => <StyledContainer>{title}</StyledContainer>;

const StyledContainer = styled.div`
  top: -2.8rem;
  left: -1.6rem;
  font-weight: 500;
  font-size: 0.9rem;
  position: absolute;
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--text-color);
  padding: 0.2rem 0.5rem 0.25rem;
  background-color: var(--tag-background);
  border-radius: var(--border-radius-small);

  &::after {
    width: 0;
    top: 100%;
    height: 0;
    left: 50%;
    content: " ";
    position: absolute;
    pointer-events: none;
    border: solid transparent;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: var(--tag-background);
    border-width: 6px;
    margin-left: -4px;
  }

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    display: none;
  }
`;
