import LinkInterface from "@interfaces/LinkInterface";

const FOOTLINKS: LinkInterface[] = [
  {
    to: "/#contact",
    title: "Contact",
  },
  {
    to: "terms",
    title: "Terms & Conditions",
  },
];

export default FOOTLINKS;
