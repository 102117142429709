import React from "react";
import styled from "styled-components";
import { Link, globalHistory } from "@reach/router";

import Image from "@components/Image";
import Button from "@components/Button";

import useSiteData from "@hooks/useSiteData";

export default () => {
  const {
    company: { name },
  } = useSiteData();

  const isHomepage = Boolean(globalHistory.location.pathname === "/");

  return (
    <StyledHeader isHomepage={isHomepage} className="pt-8 pb-12">
      <Link to="/">
        <Image filename="logo.png" alt={name} />
      </Link>
      {isHomepage && (
        <StyledNavigation className="pt-8">
          <Link to="/#contact">
            <Button className="outline">Contact</Button>
          </Link>
        </StyledNavigation>
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled.div<{ isHomepage: boolean }>`
  display: grid;
  position: relative;
  align-items: top;
  justify-content: space-between;
  grid-template-columns: 235px 1fr;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    grid-template-columns: 190px 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    max-width: 250px;
    grid-template-columns: 1fr;

    &&& {
      margin: 0 auto;
      grid-gap: 1.5rem;
      padding-bottom: ${({ isHomepage }) => (isHomepage ? 0 : "2.5rem")};
    }
  }
`;

const StyledNavigation = styled.div`
  display: grid;
  margin-left: auto;
  align-items: center;
  grid-template-columns: 130px;
  grid-gap: calc(var(--grid-gap) / 2);

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    &&& {
      padding-top: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    margin-left: 0;
    justify-content: center;
  }
`;
