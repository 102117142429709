import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    --font-size: 16px;
    --line-height: 1.5;
    --font-family: "Open Sans", sans-serif;

    --grid-gap: 3rem;
    --tag-background: #2e323a;
    --icon-background: #20242a;
    
    --border-radius: 10px;
    --border-radius-small: 3px;

    --border-color: #2b2f33;
    
    --background-color: #1d2026;
    --background-color-2: #1c1f25;
    --background-color-3: #1b1e22;
    --background-color-start: #191c20;

    --text-color: #8a94a7;
    --text-color-2: #6e7480;

    --brand-color-white: #d1d2d3;
    --brand-color-primary: #e40e7d;
    --brand-color-primary-darker: #7f224e;
    --brand-color-grey: #aeaeae;
    --brand-color-grey-darker: #747373;

    --dark-input-border-color: rgba(138, 148, 167, 0.24);

    --dark-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    --dark-box-shadow-block: 12px 12px 0px rgba(0, 0, 0, 0.15);
  }

  * {
    box-sizing: border-box;
    unicode-bidi: plaintext;
  }
    
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: initial;
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--text-color);
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: var(--font-family);
    background: var(--background-color);
  }
  
  p {
    color: var(--text-color-2);
    line-height: var(--line-height);
  }
  
  a {
    text-decoration: none;
    color: var(--brand-color-primary);
  }

  a:hover {
    color: var(--brand-color-primary-darker);
  }
  
  strong {
    font-weight: 600;
  }
  
  button, [type=button] {
    -webkit-appearance: none;
  }
  
  img {
    max-width: 100%;
  }

  h1, h2, h3, h4 {
    font-weight: 500;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--brand-color-white);
  }

  ul, li {
    list-style: none;
  }

  input, textarea {
    width: 100%;
    line-height: 1.3;
    appearance: none;
    padding: 1rem 1.25rem;
    -moz-appearance: none;
    color: var(--text-color);
    -webkit-appearance: none;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-small);
  }

  textarea {
    resize: none;
    height: 7.5rem;
  }

  input:hover,
  textarea:hover {
    background: var(--background-color-2);
  }

  input:focus,
  textarea:focus {
    outline: none;
    background: var(--background-color-3);
    border-color: var(--brand-color-primary);
  }

  :root {
    ul, li, p, a, h1, h2, h3, h4 {
      margin: 0;
      padding: 0;
    }

    .list-dashed {
      margin: 0;
      padding: 0;
    }

    .list-dashed {
      list-style-type: none;
    }
    .list-dashed > li:before {
      content: "-";
      padding-right: 5px;
    }
    
    .list-dashed > li {
      padding: .2rem;
    }
    
    .w-100 {
      width: 100%;
    }
    
    .text-underline, .text-underline:hover{
      text-decoration: underline;
    }
    
    .text-center {
      text-align: center;
    }
    
    .text-left {
      text-align: left;
    }
    
    .text-right {
      text-align: right;
    }
    
    .overflow-visible {
      overflow: visible;
    }
    
    .display-block {
      display: block !important;
    }
    
    .display-inline-block {
      display: inline-block !important;
    }
    
    .mt-a {
      margin-top: auto;
    }
    .mt-0 {
      margin-top: 0;
    }
    .mt-1 {
      margin-top: 0.25rem;
    }
    .mt-2 {
      margin-top: 0.5rem;
    }
    .mt-3 {
      margin-top: 0.75rem;
    }
    .mt-4 {
      margin-top: 1rem;
    }
    .mt-5 {
      margin-top: 1.25rem;
    }
    .mt-6 {
      margin-top: 1.50rem;
    }
    .mt-7 {
      margin-top: 1.75rem;
    }
    .mt-8 {
      margin-top: 2rem;
    }
    .mt-8 {
      margin-top: 2.25rem;
    }
    .mt-8 {
      margin-top: 2.5rem;
    }
    .mt-8 {
      margin-top: 2.75rem;
    }
    .mt-8 {
      margin-top: 3rem;
    }
    
    .mr-a {
      margin-right: auto;
    }
    .mr-0 {
      margin-right: 0;
    }
    .mr-1 {
      margin-right: 0.25rem;
    }
    .mr-2 {
      margin-right: 0.5rem;
    }
    .mr-3 {
      margin-right: 0.75rem;
    }
    .mr-4 {
      margin-right: 1rem;
    }
    .mr-8 {
      margin-right: 2rem;
    }
    
    .mb-a {
      margin-bottom: auto;
    }
    .mb-0 {
      margin-bottom: 0;
    }
    .mb-1 {
      margin-bottom: 0.25rem;
    }
    .mb-2 {
      margin-bottom: 0.5rem;
    }
    .mb-3 {
      margin-bottom: 0.75rem;
    }
    .mb-4 {
      margin-bottom: 1rem;
    }
    .mb-5 {
      margin-bottom: 1.25rem;
    }
    .mb-6 {
      margin-bottom: 1.5rem;
    }
    .mb-7 {
      margin-bottom: 1.75rem;
    }
    .mb-8 {
      margin-bottom: 2rem;
    }
    .mb-9 {
      margin-bottom: 2.25rem;
    }
    .mb-10 {
      margin-bottom: 2.5rem;
    }
    .mb-11 {
      margin-bottom: 2.75rem;
    }
    .mb-12 {
      margin-bottom: 3rem;
    }
    
    .ml-a {
      margin-left: auto;
    }
    .ml-0 {
      margin-left: 0;
    }
    .ml-1 {
      margin-left: 0.25rem;
    }
    .ml-2 {
      margin-left: 0.5rem;
    }
    .ml-3 {
      margin-left: 0.75rem;
    }
    .ml-4 {
      margin-left: 1rem;
    }
    
    .pt-0 {
      padding-top: 0;
    }
    .pt-1 {
      padding-top: 0.25rem;
    }
    .pt-2 {
      padding-top: 0.5rem;
    }
    .pt-3 {
      padding-top: 0.75rem;
    }
    .pt-4 {
      padding-top: 1rem;
    }
    .pt-6 {
      padding-top: 1.5rem;
    }
    .pt-8 {
      padding-top: 2rem;
    }
    .pt-10 {
      padding-top: 2.5rem;
    }
    .pt-12 {
      padding-top: 3rem;
    }
    .pt-14 {
      padding-top: 3.5rem;
    }
    .pt-16 {
      padding-top: 4rem;
    }
    .pt-18 {
      padding-top: 4.5rem;
    }
    
    .pr-0 {
      padding-right: 0;
    }
    .pr-1 {
      padding-right: 0.25rem;
    }
    .pr-2 {
      padding-right: 0.5rem;
    }
    .pr-3 {
      padding-right: 0.75rem;
    }
    .pr-4 {
      padding-right: 1rem;
    }
    .pr-6 {
      padding-right: 1.5rem;
    }
    .pr-8 {
      padding-right: 2rem;
    }
    .pr-9 {
      padding-right: 2.25rem;
    }
    .pr-10 {
      padding-right: 2.5rem;
    }
    
    .pb-0 {
      padding-bottom: 0;
    }
    .pb-1 {
      padding-bottom: 0.25rem;
    }
    .pb-2 {
      padding-bottom: 0.5rem;
    }
    .pb-3 {
      padding-bottom: 0.75rem;
    }
    .pb-4 {
      padding-bottom: 1rem;
    }
    .pb-6 {
      padding-bottom: 1.5rem;
    }
    .pb-8 {
      padding-bottom: 2rem;
    }
    .pb-10 {
      padding-bottom: 2.5rem;
    }
    .pb-12 {
      padding-bottom: 3rem;
    }
    .pb-14 {
      padding-bottom: 3.5rem;
    }
    .pb-16 {
      padding-bottom: 4rem;
    }
    .pb-18 {
      padding-bottom: 4.5rem;
    }
    
    .pl-0 {
      padding-left: 0;
    }
    .pl-1 {
      padding-left: 0.25rem;
    }
    .pl-2 {
      padding-left: 0.5rem;
    }
    .pl-3 {
      padding-left: 0.75rem;
    }
    .pl-4 {
      padding-left: 1rem;
    }
    .pl-6 {
      padding-left: 1.5rem;
    }
    .pl-8 {
      padding-left: 2rem;
    }
    .pl-9 {
      padding-left: 2.25rem;
    }
    .pl-10 {
      padding-left: 2.5rem;
    }
  }

  ::selection {
    color: var(--brand-color-white);
    background: var(--brand-color-primary);
  }

`;