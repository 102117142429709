import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Header from "@components/Header";
import Footer from "@components/Footer";
import Background from "@components/Background";

import useSiteData from "@hooks/useSiteData";

export default ({ children }) => {
  const {
    company: { name },
  } = useSiteData();

  return !name ? (
    <></>
  ) : (
    <>
      <Helmet defer={false} titleTemplate={`%s | ${name}`} />
      <Background>
        <StyledContainer>
          <Header />
          {children}
          <Footer />
        </StyledContainer>
      </Background>
    </>
  );
};

const StyledContainer = styled.div`
  z-index: 2;
  margin: 0 auto;
  min-width: 320px;
  max-width: 1200px;
  min-height: 800px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    padding: 0 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    padding: 0 1.5rem;
  }
`;
