import React from "react";
import styled from "styled-components";

interface Props extends React.ButtonHTMLAttributes {
  className?: string;
  children: React.ReactNode;
}

export default (props: Props) => (
  <StyledButton {...props}>{props.children}</StyledButton>
);

const StyledButton = styled.button`
  width: 100%;
  color: white;
  border: none;
  height: 52px;
  cursor: pointer;
  line-height: 1.1;
  font-weight: 500;
  padding: 0 1.5rem;
  text-transform: uppercase;
  border-radius: var(--border-radius-small);
  background-color: var(--brand-color-primary);

  &:hover,
  &:disabled {
    background-color: var(--brand-color-primary-darker);
  }

  &:disabled {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &.outline {
    height: 50px;
    color: var(--text-color);
    background-color: transparent;
    border: 1px solid var(--text-color);

    &:hover {
      color: var(--text-color-2);
      border: 1px solid var(--text-color-2);
    }
  }
`;
