/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import cssVars from "css-vars-ponyfill";
import { ThemeProvider } from "styled-components";

import Layout from "@components/Layout";
import ThemeStyles from "@styles/ThemeStyles";
import BREAKPOINTS from "@constants/breakpoints";

import "normalize.css/normalize.css";

cssVars({ watch: true });

export const wrapPageElement = ({ element }) =>  (
  <ThemeProvider theme={BREAKPOINTS}>
    <Layout>
      <ThemeStyles />
      {element}
    </Layout>
  </ThemeProvider>
);
