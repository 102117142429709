import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

/**
 * Image renderer
 *
 * Gatsby does not support dynamic file names in their GraphQL queries.
 * This is problematic because of the directory structure in multi
 * level in the assets folder. This acts as a workaround for this
 * by matching the filename and extension directly from the
 * query to find the image needed.
 */
interface Props {
  alt: string;
  filename: string;
  className?: string;
}

export default ({ filename, alt, className }: Props) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.allImageSharp.edges.find(
        (edge: {
          node: {
            fluid: { originalName: string };
          };
        }) => edge.node.fluid.originalName === filename
      );
      if (!image) {
        return <></>;
      }

      return (
        <Img
          alt={alt}
          fadeIn={true}
          className={className}
          fluid={image.node.fluid}
        />
      );
    }}
  />
);
