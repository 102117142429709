import React from "react";
import styled from "styled-components";
import { globalHistory } from "@reach/router";
import ScrollAnimation from "react-animate-on-scroll";

import "animate.css/animate.min.css";

import backgroundImage from "@assets/images/backgrounds/header.jpg";

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) =>
  /** Only display on homepage */
  globalHistory.location.pathname !== "/" ? (
    <>{children}</>
  ) : (
    <ScrollAnimation duration={0.5} animateIn="fadeIn" animateOnce={true}>
      <StyledContainer>
        {children}
        <StyledOverlay className="display-block" />
      </StyledContainer>
    </ScrollAnimation>
  );

const StyledContainer = styled.div`
  z-index: 0;
  position: relative;

  &:before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 17.5%;
    min-height: 300px;
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(${backgroundImage});
    background-color: var(--background-color-start);
  }

  &:after {
    content: "";
    left: 0;
    right: 0;
    top: 7.5%;
    height: 10%;
    display: block;
    position: absolute;
    background-image: linear-gradient(
      to bottom,
      transparent,
      var(--background-color)
    );
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    &:before {
      background-size: cover;
    }
  }
`;

const StyledOverlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.9;
  height: 17.5%;
  position: absolute;
  background-color: var(--background-color);
`;
